var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ChartsStyled', {
    attrs: {
      "id": _vm.group.asset.name
    }
  }, _vm._l(_vm.group.signals, function (signal) {
    return _c('DataFetcher', {
      key: "".concat(signal.asset.id, "_").concat(signal.signalId),
      attrs: {
        "signal": signal
      },
      on: {
        "set-data": function setData($event) {
          return _vm.setData(signal, $event);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }