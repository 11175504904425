var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalFooterStyled', [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('toggleMobileActiveArea', 'search');
      }
    }
  }, [_c('SearchIcon')], 1), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('toggleMobileActiveArea', 'controls');
      }
    }
  }, [_c('ListIcon')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }