var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SelectedSignalStyled', [_c('button', {
    staticClass: "toggle button-part",
    on: {
      "click": function click($event) {
        _vm.isExpanded = !_vm.isExpanded;
      }
    }
  }, [_c('ChevronRightIcon', {
    staticClass: "icon",
    class: {
      rotate: _vm.isExpanded
    }
  }), _c('div', {
    staticClass: "name"
  }, [_c('span', {
    staticClass: "signal-name"
  }, [_vm._v(_vm._s(_vm.signal.translatedName) + " ")]), _vm.selectedAggregator ? _c('span', {
    staticClass: "signal-aggregator"
  }, [_vm._v("(" + _vm._s(_vm.selectedAggregator.label) + ")")]) : _vm._e()])], 1), _c('transition-group', {
    staticClass: "stats button-part",
    attrs: {
      "name": "fade-in-right",
      "tag": "div"
    }
  }, [_vm.signal.selectedInterval && _vm.signalData ? [_c('div', {
    key: _vm.signal.signalId + '_min',
    staticClass: "min"
  }, [_vm.signal.isLoading || _vm.$apollo.queries.signalData.loading ? _c('LoadingDots') : [_c('div', {
    staticClass: "label"
  }, [_vm._v("Min.")]), _vm.signalData.minimum === null ? _c('div', {
    staticClass: "value"
  }, [_vm._v("-")]) : _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("round")(_vm.signalData.minimum, 0)))])]], 2), _c('div', {
    key: _vm.signal.signalId + '_max',
    staticClass: "max"
  }, [_vm.signal.isLoading || _vm.$apollo.queries.signalData.loading ? _c('LoadingDots') : [_c('div', {
    staticClass: "label"
  }, [_vm._v("Max.")]), _vm.signalData.maximum === null ? _c('div', {
    staticClass: "value"
  }, [_vm._v("-")]) : _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("round")(_vm.signalData.maximum, 0)))])]], 2), _c('div', {
    key: _vm.signal.signalId + '_mean',
    staticClass: "mean"
  }, [_vm.signal.isLoading || _vm.$apollo.queries.signalData.loading ? _c('LoadingDots') : [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ø")]), _vm.signalData.mean === null ? _c('div', {
    staticClass: "value"
  }, [_vm._v("-")]) : _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("round")(_vm.signalData.mean, 0)))])]], 2)] : _vm._e()], 2), _c('VPopover', {
    staticClass: "button-part color",
    attrs: {
      "placement": 'top',
      "popperOptions": _vm.popperOptions
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('ColorPicker', {
          attrs: {
            "color": _vm.signal.color
          },
          on: {
            "update:color": _vm.updateColor
          }
        })];
      },
      proxy: true
    }])
  }, [_c('ColorBadgeStyled', {
    attrs: {
      "color": _vm.signal.color
    }
  })], 1), _c('button', {
    staticClass: "hide button-part",
    on: {
      "click": _vm.toggleSignalHidden
    }
  }, [_vm.signal.isHidden ? _c('EyeOffIcon') : _c('EyeIcon')], 1), _c('button', {
    staticClass: "delete button-part",
    on: {
      "click": _vm.toggleSignalSelected
    }
  }, [_c('TrashIcon')], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isExpanded ? _c('div', {
    staticClass: "details"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$tc('signals.aggregation.label', 1)) + " ")]), _c('div', {
    staticClass: "aggregators"
  }, _vm._l(_vm.availableAggregatorsMapped, function (aggregator) {
    return _c('AggregatorButtonStyled', {
      key: aggregator.id,
      class: {
        active: aggregator === _vm.selectedAggregator
      },
      on: {
        "click": function click($event) {
          return _vm.setSignalAggregator(aggregator);
        }
      }
    }, [_vm._v(" " + _vm._s(aggregator.label) + " ")]);
  }), 1)]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }