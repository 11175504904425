var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalSearchStyled', {
    class: {
      active: _vm.mobileIsActive
    }
  }, [_c('TrayExpanderStyled', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleSearchTray();
      }
    }
  }, [!_vm.searchTrayExpanded ? _c('ChevronLeftIcon') : _c('ChevronRightIcon')], 1), _c('h2', [_vm._v(_vm._s(_vm.$t('signals.headers.search')))]), _c('Multiselect', {
    attrs: {
      "value": _vm.selectedAsset,
      "multiple": false,
      "allow-empty": false,
      "options": _vm.assets,
      "loading": _vm.assetsLoading,
      "showLabels": false,
      "placeholder": _vm.$tc('selects.asset', 1),
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.handleAssetSelect
    }
  }), _vm.selectedAssetId ? [_c('SearchStyled', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    attrs: {
      "placeholder": _vm.$t('signals.search.placeholder')
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _c('SearchIcon')], 1), _vm.$apollo.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('LoadingDots')], 1) : _c('AvailableSignalsStyled', [_c('h2', [_vm._v(_vm._s(_vm.signalsFiltered.length) + " / " + _vm._s(_vm.signals.length))]), _c('div', {
    staticClass: "signal-list"
  }, _vm._l(_vm.signalsFiltered, function (signal) {
    return _c('AvailableSignal', {
      key: signal.signalId,
      attrs: {
        "signal": signal,
        "selectedAsset": _vm.selectedAsset,
        "searchQueries": _vm.searchQueries
      }
    });
  }), 1)])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }