<template>
  <SignalTimelinesStyled>
    <h2>{{ $t('signals.headers.timelines') }}</h2>
    <transition-group name="fade">
      <AssetSignalsTimeline
        v-for="group in signalsGrouped"
        :key="group.asset.id"
        :group="group"
        @update-sub-selection="$emit('update-sub-selection', $event)"
      />
    </transition-group>
  </SignalTimelinesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { panel } from '@styles/mixins'

import AssetSignalsTimeline from './SignalTimelines/AssetSignalsTimeline'

import { useStore } from '../../../stores/signals'

const SignalTimelinesStyled = styled('div')`
  ${panel}
  grid-area: timelines;
  overflow-y: auto;
  max-height: 10rem;
`

export default {
  components: {
    SignalTimelinesStyled,
    AssetSignalsTimeline,
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  computed: {
    signalsGrouped() {
      return this.signalStore.signalsGroupedByAsset
    },
  },
}
</script>
