var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PreviewChartStyled', {
    class: {
      active: _vm.datasets.length > 0
    }
  }, [_vm._l(_vm.group.signals, function (signal) {
    return _c('DataFetcher', {
      key: "".concat(signal.asset.id, "_").concat(signal.signalId),
      attrs: {
        "signal": signal
      },
      on: {
        "set-data": function setData($event) {
          return _vm.setData(signal, $event);
        }
      }
    });
  }), _c('OverlayCanvasStyled', {
    ref: "overlay",
    attrs: {
      "width": _vm.width,
      "height": _vm.height
    }
  }), _c('canvas', {
    ref: "chartCanvas",
    attrs: {
      "width": _vm.width,
      "height": _vm.height
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }