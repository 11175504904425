var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalControlsStyled', {
    class: {
      active: _vm.mobileIsActive
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('signals.headers.controls')))]), _c('transition-group', {
    attrs: {
      "name": "fade"
    }
  }, [_vm._l(_vm.signalsGrouped, function (group) {
    return [_c('h3', {
      key: group.asset.id
    }, [_vm._v(_vm._s(group.asset.name))]), _vm._l(group.signals, function (signal) {
      return _c('SelectedSignal', {
        key: "".concat(signal.asset.id, "_").concat(signal.signalId),
        attrs: {
          "signal": signal
        }
      });
    })];
  })], 2), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }