<template>
  <AvailableSignalStyled
    class="signals-search-signal"
    :class="{ selected: isSelected }"
    @click="toggleSignalSelected(signal)"
    v-tooltip.right="tooltip"
  >
    <div class="icon">
      <XCircleIcon v-if="isSelected" />
      <PlusCircleIcon v-else />
    </div>
    <TextHighlight :highlightStyle="{ background: 'rgba(255, 204, 0, 0.64)' }" :queries="searchQueries">
      {{ signal.translatedName }}
    </TextHighlight>
  </AvailableSignalStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import TextHighlight from 'vue-text-highlight'
import { VTooltip } from 'v-tooltip'
import get from 'lodash/get'
import toPairs from 'lodash/toPairs'
import { XCircleIcon, PlusCircleIcon } from 'vue-feather-icons'
import { DateTime } from 'luxon'
import { buttonReset, flexCenter } from '@styles/mixins'
import { useStore } from '../../../../stores/signals'

const AvailableSignalStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  position: relative;
  color: ${({ theme }) => theme.colors.textPrimary};
  background: ${({ theme }) => theme.colors.mainBG};
  border-radius: 3px;
  margin-top: 5px;
  padding: 3px 3px;

  font-size: 0.85rem;

  > span {
    flex-grow: 1;
    padding-left: 0.5rem;
    text-align: left;
    width: calc(100% - 20px - 1rem);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    position: relative;
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }

    .check,
    .remove {
      position: absolute;
      left: 0;
      top: 0;
      transition: opacity 0.5s, transform 0.5s;
    }

    .check {
      opacity: 1;
      transform: translateX(0px);
      fill: none;
    }
    .remove {
      opacity: 0;
      transform: translateX(-20px);
    }
  }

  &:hover {
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.green};
    .icon {
      .check {
        opacity: 0;
        transform: translateX(25px);
      }
      .remove {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }

  &.selected {
    opacity: 0.5;
    color: ${({ theme }) => theme.textActivePrimary};

    &:hover {
      opacity: 1;
      background: ${p => p.theme.colors.red};
    }
  }
`

export default {
  inject: ['uiSettings', 'theme'],
  props: {
    signal: {
      type: Object,
      required: true,
    },
    searchQueries: {
      type: Array,
      required: true,
    },
    selectedAsset: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  components: {
    AvailableSignalStyled,
    XCircleIcon,
    PlusCircleIcon,
    TextHighlight,
  },
  directives: {
    tooltip: VTooltip,
  },
  computed: {
    isSelected() {
      return this.signalStore.selectedSignals.find(f => this.signal.signalId === f.signalId && this.selectedAsset.id === f.asset.id)
    },
    tooltip() {
      return {
        content: this.tooltipContent,
        boundariesElement: 'body',
      }
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
    tooltipContent() {
      const locale = this.locale

      const styles = {
        background: this.theme.colors.backgroundPrimary,
        color: this.theme.colors.textPrimary,
        padding: '10px',
      }

      const stylesString = toPairs(styles).reduce((str, [key, value]) => `${str} ${key}:${value};`, '')

      return `
        <div style="${stylesString}">
          ${this.$tc('signals.tooltip.name')}: ${this.signal.translatedName}<br />
          ${this.signal.translations.length > 1 ? this.$tc('signals.tooltip.defaultName') + ': ' + this.signal.longName + '<br />' : ''}
          ${this.$tc('signals.tooltip.unit')}: ${this.signal.signalUnit?.name}<br />
          ${
            this.signal.validFrom != null
              ? this.$tc('signals.tooltip.firstDataReceived') +
                ': ' +
                DateTime.fromISO(this.signal.validFrom).setLocale(locale).toLocaleString(Object.assign(DateTime.DATETIME_SHORT)) +
                '<br />'
              : ''
          }
          ${
            this.signal.validTill != null
              ? this.$tc('signals.tooltip.lastDataReceived') +
                ': ' +
                DateTime.fromISO(this.signal.validTill).setLocale(locale).toLocaleString(Object.assign(DateTime.DATETIME_SHORT)) +
                '<br />'
              : ''
          }
        </div>
      `
    },
  },
  methods: {
    async toggleSignalSelected(signal) {
      const eventData = {
        shortName: signal.shortName,
        signalId: signal.signalId,
        type: 'FIRST',
        assetName: this.selectedAsset.name,
      }

      if (!this.isSelected) {
        this.signalStore.selectSignal(signal, this.selectedAsset)
        this.$posthog.capture('Signals Signal Selected', { signalData: eventData })
      } else {
        this.signalStore.unselectSignal(signal.signalId, this.selectedAsset.id)
        this.$posthog.capture('Signals Signal Removed', { signalData: eventData })
      }
    },
    unitConverter(unit) {
      let customUnit = unit
      if (unit === 'degrees c') {
        customUnit = '°C'
      } else if (unit === 'percent') {
        customUnit = '%'
      }
      return customUnit
    },
  },
}
</script>
