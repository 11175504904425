var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalGroupStyled', [_c('SignalGroupHeaderStyled', [_c('ButtonSolid', {
    class: !_vm.canMergeCharts ? 'active' : 'disabled',
    on: {
      "click": _vm.singleChart
    }
  }, [_c('SingleChartIcon', {
    attrs: {
      "width": "30",
      "height": "30"
    }
  })], 1), _c('ButtonSolid', {
    class: !_vm.canSplitCharts ? 'active' : 'disabled',
    on: {
      "click": _vm.splitCharts
    }
  }, [_c('SplitChartIcon', {
    attrs: {
      "width": "30",
      "height": "30"
    }
  })], 1), _c('SignalGroupAssetNameStyled', [_vm._v(" " + _vm._s(_vm.group.asset.name) + " ")])], 1), _c('SignalGroupChartStyled', [_c('Chart', {
    ref: 'chart',
    attrs: {
      "group": _vm.group,
      "splitView": _vm.splitView,
      "selectedRange": _vm.selectedRange
    },
    on: {
      "setSplitView": _vm.setSplitView,
      "setSelectedRange": _vm.setSelectedRange
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }