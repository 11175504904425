var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalsStyled', [_vm._l(_vm.signalsGroupedWithSubSelection, function (group) {
    return _c('SignalGroup', {
      key: group.asset.id,
      attrs: {
        "group": group
      }
    });
  }), _c('transition', {
    attrs: {
      "name": "fade-in-left"
    }
  }, [_vm.signalsLoading.length > 0 ? _c('LoadingIndicatorStyled', [_c('LoadingDots'), _c('span', [_vm._v("loading " + _vm._s(_vm.signalsLoading.length))])], 1) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }