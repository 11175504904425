<template>
  <SignalDatetimePickerStyled>
    <TimezonePicker :hovering="infoHovering">
      <div class="row">
        <span>{{ $t('timezone.timezone') }} : </span>
        <Collabsible :collabsed="timezoneSettingsCollapsed">
          <Toggler id="collapsible" @input="toggleTimezoneSettings" type="checkbox" />
          <label for="collapsible" class="lbl-toggle">
            {{ selectedTimezone.title }}
            <span class="localZone" v-if="selectedTimezone.id === 'browser'"> (UTC{{ localTimezone }}) </span>
          </label>
        </Collabsible>
        <InfoIconStyled @mouseover="infoHovering = true" @mouseleave="infoHovering = false" />
        <div class="dropdown">
          <div class="dropdown-content">
            <div class="timezone-item" :key="index" v-for="(item, index) in timezoneOptions">
              <span class="title">{{ $t(`timezone.${item.id}.title`) }}</span> <br />
              <span class="description">{{ $t(`timezone.${item.id}.description`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <CollapsibleContent v-show="timezoneSettingsCollapsed">
        <TimezoneSettings>
          <ButtonSolidStyled
            class="box"
            :key="index"
            v-for="(option, index) in timezoneOptions"
            :class="{ selected: selectedTimezone.id === option.id }"
            :disabled="true"
            @click="setTimezone(option)"
          >
            {{ option.title }}
          </ButtonSolidStyled>
        </TimezoneSettings>
      </CollapsibleContent>
    </TimezonePicker>
    <DateTimePickerStyled :class="{ days: selectedDayHours.length > 0 }">
      <template v-if="selectedDayHours.length > 0">
        <header class="days">
          <button class="prev" @click="goBack">
            <ArrowLeftIcon />
          </button>
          <div class="select text">
            {{ selectedDayLabel }}
          </div>
          <div class="next" />
        </header>
        <HourStyled
          class="hour"
          v-for="hour in selectedDayHours"
          :key="hour.id"
          :percent="hour.percent"
          :isCurrent="hour.isCurrent"
          :title="hour.title"
          @click="setSelectedHour(hour)"
        >
          {{ hour.label }}
        </HourStyled>
      </template>
      <template v-else>
        <header class="calendar">
          <button class="prev" @click="prev" :disabled="!canPrev">
            <ChevronLeftIcon />
          </button>
          <Multiselect
            class="select"
            :options="monthYears"
            :value="selectedMonthYear"
            :showLabels="false"
            :multiple="false"
            :allow-empty="false"
            :searchable="false"
            trackBy="id"
            label="label"
            @select="setSelectedMonthInterval"
          />
          <button class="next" @click="next" :disabled="!canNext">
            <ChevronRightIcon />
          </button>
        </header>
        <div class="wday" v-for="(wd, i) in weekDays" :key="i">
          {{ wd.label }}
        </div>
        <DayStyled
          class="day"
          v-for="day in selectedMonthDays"
          :key="day.id"
          :percent="day.percent"
          :isCurrent="day.isCurrent"
          :isCurrentMonth="day.isCurrentMonth"
          :title="day.title"
          @click="setSelectedDay(day)"
        >
          {{ day.date.day }}
        </DayStyled>
      </template>
      <transition name="fade">
        <div class="loading" v-if="$apollo.queries.assetAvailableDataIntervals.loading">
          <LoadingDots />
        </div>
      </transition>
    </DateTimePickerStyled>
  </SignalDatetimePickerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import chroma from 'chroma-js'
import { DateTime, Interval } from 'luxon'
import { ScrollStyles } from '@styles/misc'
import Multiselect from 'vue-multiselect'
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, InfoIcon } from 'vue-feather-icons'
import { flexCenter, buttonReset } from '@styles/mixins'
import { ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import { LoadingDots } from '@common/components'
import { useStore } from '../../../../../stores/signals'

import ASSET_DATA_AVAILABILITY_FOR_TIME_PERIOD_QUERY from '#/graphql/misc/assetDataAvailabilityForTimePeriod.gql'

const SignalDatetimePickerStyled = styled('div')`
  color: ${p => p.theme.colors.normalFontColor};
  background: ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.solidBG)};
  box-shadow: ${p => p.theme.colors.widgetShadow};
`

const CollapsibleContent = styled('div')`
  background: ${p => p.theme.colors.solidBG};
  border-bottom: 1px solid ${props => chroma(props.theme.colors.white).alpha(0.2).css()};
`

const InfoIconStyled = styled(InfoIcon)`
  :hover {
    color: ${p => p.theme.colors.primary};
  }
`

const Collabsible = styled('div')`
  width: 60%;
  .lbl-toggle {
    padding: 5px;
    display: block;
    text-align: center;
    background: ${p => p.theme.colors.circleIconBG};
    color: ${p => p.theme.colors.primary};
    cursor: pointer;
    transition: all 0.25s ease-out;
  }
  .lbl-toggle::after {
    content: '';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-left: 0.7rem;
    transform: ${p => (p.collabsed ? 'rotate(90deg) translateX(-3px)' : 'translateY(-2px)')};
    transition: transform 0.2s ease-out;
  }
  .localZone {
    font-size: 14px;
  }
`

const Toggler = styled('input')`
  display: none;
`

const TimezonePicker = styled('div')`
  background: ${p => (p.theme.isDark ? p.theme.colors.darkerGrey : p.theme.colors.solidBG)};

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 5px;
    background: ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.solidBG)};
    border-bottom: 1px solid ${props => chroma(props.theme.colors.white).alpha(0.2).css()};
    svg {
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
    .dropdown {
      position: relative;
      display: inline-block;

      .dropdown-content {
        background: ${p => (p.theme.isDark ? '#1d1d1d' : 'rgb(206 206 206)')};
        color: ${p => (p.theme.isDark ? 'white' : 'black')};
        display: ${p => (p.hovering ? 'block' : 'none')};
        transition: width height 0.5s ease-out;
        position: absolute;
        top: 25px;
        right: 0;
        right: -5px;
        width: ${p => (p.hovering ? '295px' : '0')};
        z-index: 1;

        .timezone-item {
          padding: 15px;
          font-size: 13px;
          border-bottom: 1px solid;
          .title {
            color: ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }
`

const TimezoneSettings = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .box {
    padding: 20px;
  }
  .selected {
    background: ${p => chroma(p.theme.colors.primary).darken(0.1).css()};
    color: white;
  }

  button {
    background: ${p => p.theme.colors.circleIconBG};
    width: 100px;
  }
`

const DateTimePickerStyled = styled('div')`
  position: relative;
  display: grid;
  color: ${p => p.theme.colors.normalFontColor};
  background: ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.solidBG)};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  padding: 0.25rem;
  > header {
    grid-area: header;
    display: grid;
    grid-gap: 1px;
    grid-auto-rows: 40px;
    grid-template-columns: repeat(7, 40px);
    &.calendar {
      grid-template-areas: 'prev select select select select select next';
    }
    &.days {
      grid-template-areas: 'prev select select select select select select next';
      grid-template-columns: repeat(8, 35px);
    }
    .prev,
    .next {
      ${buttonReset}
      ${flexCenter}
      color: ${p => p.theme.colors.primary};
    }
    .prev {
      grid-area: prev;
    }
    .next {
      grid-area: next;
    }
    .select {
      ${flexCenter}
      grid-area: select;
      &.text {
        /* position: sticky; */
        top: 0;
        background: ${p => p.theme.colors.solidBG};
        font-weight: bold;
      }
      box-sizing: content-box;
      text-align: center;
      &:focus {
        outline: none;
        .multiselect__tags {
          color: ${props => chroma(props.theme.colors.primary).alpha(1).css()};
        }
      }
      .multiselect__content-wrapper {
        position: absolute;
        z-index: 50;
        top: 45px;
        box-sizing: border-box;
        background: ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.solidBG)};
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid ${props => chroma(props.theme.colors.primary).alpha(0.9).css()};
        border-top: 1px solid ${props => chroma(props.theme.colors.white).alpha(0.2).css()};
        ${ScrollStyles}
        .multiselect__content {
          list-style: none;
          vertical-align: top;
          text-align: center;
          margin: 0px;
          padding: 0px;
          width: 100%;
          .multiselect__element {
            cursor: pointer;
            display: block;
            padding: 12px;
            line-height: 16px;
            &:hover {
              color: ${props => props.theme.colors.white};
              background: ${props => props.theme.colors.primaryActive};
            }
          }
        }
      }
    }
  }

  .wday {
    ${flexCenter}
    color: ${p => p.theme.colors.muted};
  }
  grid-gap: 1px;
  grid-auto-rows: 40px;
  grid-template-columns: repeat(7, 40px);
  max-height: calc(8 * 41px);
  grid-template-areas:
    'header header header header header header header'
    'wday wday wday wday wday wday wday';

  &.days {
    grid-template-columns: repeat(8, 35px);
    grid-template-areas: 'header header header header header header header header';
  }

  .loading {
    ${flexCenter};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) =>
      chroma(theme.isDark ? theme.colors.black : theme.colors.white)
        .alpha(0.8)
        .css()};
  }
`

const DayStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  position: relative;
  color: ${p => {
    if (p.isCurrent) {
      return p.theme.colors.primary
    }
    if (p.isCurrentMonth) {
      return p.theme.colors.normalFontColor
    }
    return p.theme.colors.muted
  }};
  &::after {
    content: '';
    position: absolute;
    left: 0.25rem;
    width: 3px;
    height: ${p => p.percent * 60}%;
    background: ${p => p.theme.colors.primary};
  }
`

const HourStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  position: relative;
  grid-column: span 2;
  color: ${p => (p.isCurrent ? p.theme.colors.primary : p.theme.colors.normalFontColor)};
  &::after {
    content: '';
    position: absolute;
    left: 0.25rem;
    width: 3px;
    height: ${p => p.percent * 60}%;
    background: ${p => p.theme.colors.primary};
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    selectedInterval: {
      type: Object,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
  },
  components: {
    SignalDatetimePickerStyled,
    DateTimePickerStyled,
    DayStyled,
    ChevronLeftIcon,
    ChevronRightIcon,
    ArrowLeftIcon,
    HourStyled,
    Multiselect,
    LoadingDots,
    TimezonePicker,
    TimezoneSettings,
    CollapsibleContent,
    InfoIconStyled,
    Collabsible,
    Toggler,
    ButtonSolidStyled,
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  data() {
    return {
      selectedMonthInterval: null,
      intervalForData: null,
      assetAvailableDataIntervals: [],
      selectedDay: null,
      timezoneSettingsCollapsed: false,
      timezoneOptions: [
        { id: 'browser', title: 'Browser', value: DateTime.local().zoneName },
        { id: 'UTC', title: 'UTC', value: 'utc' },
      ],
      selectedTimezone: { id: 'browser', title: 'Browser', value: DateTime.local().zoneName },
      infoHovering: false,
      firstDayInView: DateTime.local()
        .startOf('month')
        .minus({ days: Math.abs(1 - DateTime.local().startOf('month').weekday) }),
    }
  },
  computed: {
    localTimezone() {
      return DateTime.local().toFormat('Z')
    },
    selectedDayHours() {
      if (this.selectedDay === null) {
        return []
      }
      const date = DateTime.fromISO(this.selectedDay.date.toISODate(), { zone: this.selectedTimezone.value })
      return Interval.fromDateTimes(date.startOf('day'), date.endOf('day'))
        .splitBy({ hours: 1 })
        .map(h => {
          const secondsInInterval = h.length('seconds')
          const localIso = h.start.toISO()
          const hour = this.selectedDay.hours.find(h => h.localIso === localIso)
          const secondsWithoutData = secondsInInterval - (hour?.availableSeconds || 0)

          const percent = secondsInInterval - secondsWithoutData > 0 ? (secondsInInterval - secondsWithoutData) / secondsInInterval : 0
          return {
            interval: h,
            isCurrent: false,
            percent,
            title: `${Math.ceil(secondsInInterval - secondsWithoutData)}s / ${Math.ceil(secondsInInterval)}s`,
            label: h.start.toLocaleString(DateTime.TIME_SIMPLE),
          }
        })
    },
    weekDays() {
      const locale = this.locale
      return Array.from(Array(7).keys()).map(i => {
        const day = this.firstDayInView.plus({ days: i })
        return {
          label: day.setLocale(locale).toLocaleString({
            weekday: 'short',
          }),
        }
      })
    },
    selectedMonthDays() {
      return Array.from(Array(7 * 6).keys()).map(i => {
        const date = DateTime.fromISO(this.firstDayInView.plus({ days: i }).toISODate(), { zone: this.selectedTimezone.value })
        const id = `day-${date.toLocaleString()}`
        const dayinInterval = Interval.fromDateTimes(date.startOf('day'), date.endOf('day'))
        const secondsInInterval = dayinInterval.length('seconds')
        const mapId = `${date.day}-${date.month}-${date.year}`
        const hours = this.daysWithData[mapId] ? this.daysWithData[mapId].hours : []
        const secondsWithoutData = secondsInInterval - (this.daysWithData[mapId]?.availableSeconds || 0)
        const percent = secondsInInterval - secondsWithoutData > 0 ? (secondsInInterval - secondsWithoutData) / secondsInInterval : 0
        return {
          id,
          date,
          percent,
          title: `${Math.ceil(secondsInInterval - secondsWithoutData)}s / ${Math.ceil(secondsInInterval)}s`,
          isCurrentMonth: this.selectedMonthInterval.contains(date),
          hours,
        }
      })
    },
    monthYears() {
      const locale = this.locale
      const localDateTime = DateTime.local()
      return Array.from(Array(24).keys()).map(i => {
        const startISO = localDateTime.minus({ months: i }).startOf('month').toISODate()
        const start = DateTime.fromISO(startISO, { zone: this.selectedTimezone.value })
        const endISO = localDateTime.minus({ months: i }).endOf('month').toISODate()
        const end = DateTime.fromISO(endISO, { zone: this.selectedTimezone.value })
        const interval = Interval.fromDateTimes(start.startOf('day'), end.endOf('day'))
        return {
          id: interval.toISO(),
          interval: interval,
          label: interval.start.setLocale(locale).toLocaleString({
            month: 'long',
            year: 'numeric',
          }),
        }
      })
    },
    selectedDayLabel() {
      const locale = this.locale
      if (this.selectedDay === null) {
        return ''
      }
      return this.selectedDay.date.setLocale(locale).toLocaleString({
        dateStyle: 'short',
      })
    },
    selectedMonthYear() {
      const locale = this.locale
      const startISO = this.selectedMonthInterval.start.toISODate()
      const start = DateTime.fromISO(startISO, { zone: this.selectedTimezone.value })
      const endISO = this.selectedMonthInterval.end.toISODate()
      const end = DateTime.fromISO(endISO, { zone: this.selectedTimezone.value })
      const interval = Interval.fromDateTimes(start.startOf('day'), end.endOf('day'))
      if (interval === null) {
        return null
      }
      return {
        id: interval.toISO(),
        interval: interval,
        label: interval.start.setLocale(locale).toLocaleString({
          month: 'long',
          year: 'numeric',
        }),
      }
    },
    canNext() {
      return (
        !this.$apollo.queries.assetAvailableDataIntervals.loading && this.monthYears.findIndex(f => f.id === this.selectedMonthYear.id) > 0
      )
    },
    canPrev() {
      return (
        !this.$apollo.queries.assetAvailableDataIntervals.loading &&
        this.monthYears.findIndex(f => f.id === this.selectedMonthYear.id) < this.monthYears.length - 1
      )
    },
    daysWithData() {
      return this.assetAvailableDataIntervals.reduce((acc, item) => {
        const dt = DateTime.fromISO(item.hour).setZone(this.selectedTimezone.value)
        const mapId = `${dt.day}-${dt.month}-${dt.year}`
        if (acc[mapId]) {
          acc[mapId].availableSeconds += item.availableSeconds
          acc[mapId].hours.push({ ...item, localIso: dt.toISO() })
        } else {
          acc[mapId] = {
            availableSeconds: item.availableSeconds,
            hours: [{ ...item, localIso: dt.toISO() }],
          }
        }
        return acc
      }, {})
    },
    locale() {
      return get(this.uiSettings, 'dates', '').toLowerCase().replace('_', '-')
    },
  },
  watch: {
    selectedInterval: {
      handler(iv) {
        this.firstDayInView = iv.start.startOf('month').startOf('week')
        this.intervalForData = Interval.fromDateTimes(this.firstDayInView.toUTC(), iv.start.endOf('month').toUTC())
        this.selectedMonthInterval = Interval.fromDateTimes(iv.start.startOf('month'), iv.start.endOf('month'))
      },
      immediate: true,
    },
  },
  methods: {
    prev() {
      if (this.firstDayInView.day === 1) {
        const prevDay = this.firstDayInView.minus({ days: 1 })
        this.firstDayInView = prevDay.startOf('month').minus({ days: Math.abs(1 - prevDay.startOf('month').weekday) })
      } else {
        this.firstDayInView = this.firstDayInView
          .startOf('month')
          .minus({ days: Math.abs(1 - this.firstDayInView.startOf('month').weekday) })
      }
      const daysinVew = this.selectedMonthDays
      this.intervalForData = Interval.fromDateTimes(this.firstDayInView, daysinVew[daysinVew.length - 1].date)
      const lastMonthFirstDay = this.selectedMonthInterval.start.minus({ months: 1 })
      this.selectedMonthInterval = Interval.fromDateTimes(lastMonthFirstDay.startOf('month'), lastMonthFirstDay.endOf('month'))
    },
    next() {
      const daysinVew = this.selectedMonthDays
      let lastDayInView = daysinVew[daysinVew.length - 1]
      lastDayInView = lastDayInView.date.plus({ days: 1 })
      this.firstDayInView = lastDayInView.startOf('month').minus({ days: Math.abs(1 - lastDayInView.startOf('month').weekday) })
      this.intervalForData = Interval.fromDateTimes(this.firstDayInView, this.selectedMonthDays[this.selectedMonthDays.length - 1].date)
      const nextMonthFIrstDay = this.selectedMonthInterval.start.plus({ months: 1 })
      this.selectedMonthInterval = Interval.fromDateTimes(nextMonthFIrstDay.startOf('month'), nextMonthFIrstDay.endOf('month'))
    },
    setSelectedDay(day) {
      this.selectedDay = day
    },
    goBack() {
      this.selectedDay = null
    },
    setSelectedMonthInterval(value) {
      this.selectedMonthInterval = Interval.fromISO(value.id)
      this.firstDayInView = this.selectedMonthInterval.start.startOf('month').startOf('week')
      this.intervalForData = Interval.fromDateTimes(this.firstDayInView, this.selectedMonthDays[this.selectedMonthDays.length - 1].date)
    },
    setSelectedHour(hour) {
      this.signalStore.setSelectedInterval(this.assetId, hour.interval)
      this.$emit('close')
    },
    toggleTimezoneSettings() {
      this.timezoneSettingsCollapsed = !this.timezoneSettingsCollapsed
    },
    setTimezone(timezone) {
      this.selectedTimezone = timezone
      this.selectedDay = null
      this.timezoneSettingsCollapsed = !this.timezoneSettingsCollapsed
      this.$emit('update:selectedTimezone', timezone)
      this.$root.$emit('changeSignalTimezone', timezone.value)
    },
  },
  apollo: {
    assetAvailableDataIntervals: {
      query: ASSET_DATA_AVAILABILITY_FOR_TIME_PERIOD_QUERY,
      variables() {
        return {
          assetId: this.assetId,
          startTs: this.intervalForData.start.startOf('week').toISO(),
          endTs: this.intervalForData.end.endOf('week').plus(1).toUTC(),
        }
      },
      skip() {
        return !this.intervalForData || !this.intervalForData || !this.assetId
      },
      update: ({ assetDataAvailabilityForTimePeriod }) => assetDataAvailabilityForTimePeriod,
    },
  },
}
</script>
