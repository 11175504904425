var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalsStyled', {
    attrs: {
      "isSearchExpanded": _vm.isSearchExpanded
    }
  }, [_c('SignalTimelines', {
    on: {
      "update-sub-selection": _vm.updateSubSelection
    }
  }), _c('Signals', {
    attrs: {
      "subSelections": _vm.subSelections
    }
  }), _c('SignalSearch', {
    staticClass: "search",
    attrs: {
      "assets": _vm.assets,
      "assetsLoading": _vm.$apollo.queries.assets.loading,
      "mobileIsActive": _vm.mobileActiveArea === 'search'
    },
    on: {
      "toggleMobileActiveArea": _vm.toggleMobileActiveArea,
      "searchExpanded": _vm.searchExpanded
    }
  }), _c('SignalControls', {
    attrs: {
      "mobileIsActive": _vm.mobileActiveArea === 'controls'
    },
    on: {
      "toggleMobileActiveArea": _vm.toggleMobileActiveArea
    }
  }), _c('SignalFooter', {
    on: {
      "toggleMobileActiveArea": _vm.toggleMobileActiveArea
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }