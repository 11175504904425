var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetSignalsTimelineStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.group.asset.name) + " ")]), _c('ControlsStyled', [_c('VPopover', {
    attrs: {
      "placement": 'bottom',
      "open": _vm.showSignalDatetimePicker,
      "popperOptions": _vm.popperOptions
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.showSignalDatetimePicker = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('SignalDatetimePicker', {
          attrs: {
            "selectedInterval": _vm.selectedInterval,
            "selectedTimezone": _vm.selectedTimezone,
            "assetId": _vm.group.asset.id
          },
          on: {
            "update:selectedInterval": function updateSelectedInterval($event) {
              _vm.selectedInterval = $event;
            },
            "update:selected-interval": function updateSelectedInterval($event) {
              _vm.selectedInterval = $event;
            },
            "update:selectedTimezone": function updateSelectedTimezone($event) {
              _vm.selectedTimezone = $event;
            },
            "update:selected-timezone": function updateSelectedTimezone($event) {
              _vm.selectedTimezone = $event;
            },
            "close": _vm.closeSignalDatetimePicker
          }
        })];
      },
      proxy: true
    }])
  }, [_c('button', {
    staticClass: "date"
  }, [_c('div', [_vm._v(_vm._s(_vm.intervalDate))]), _c('div', {
    staticClass: "times"
  }, [_vm._v(_vm._s(_vm.intervalTimes))])])])], 1), _c('PreviewWrapperStyled', [_c('PreviewChart', {
    attrs: {
      "group": _vm.group
    },
    on: {
      "update-sub-selection": function updateSubSelection($event) {
        return _vm.$emit('update-sub-selection', $event);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }