var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AvailableSignalStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.right",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "right": true
      }
    }],
    staticClass: "signals-search-signal",
    class: {
      selected: _vm.isSelected
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSignalSelected(_vm.signal);
      }
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm.isSelected ? _c('XCircleIcon') : _c('PlusCircleIcon')], 1), _c('TextHighlight', {
    attrs: {
      "highlightStyle": {
        background: 'rgba(255, 204, 0, 0.64)'
      },
      "queries": _vm.searchQueries
    }
  }, [_vm._v(" " + _vm._s(_vm.signal.translatedName) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }