<template>
  <SignalGroupStyled>
    <SignalGroupHeaderStyled>
      <ButtonSolid :class="!canMergeCharts ? 'active' : 'disabled'" @click="singleChart">
        <SingleChartIcon width="30" height="30" />
      </ButtonSolid>
      <ButtonSolid :class="!canSplitCharts ? 'active' : 'disabled'" @click="splitCharts">
        <SplitChartIcon width="30" height="30" />
      </ButtonSolid>
      <SignalGroupAssetNameStyled>
        {{ group.asset.name }}
      </SignalGroupAssetNameStyled>
    </SignalGroupHeaderStyled>
    <SignalGroupChartStyled>
      <Chart
        :ref="'chart'"
        :group="group"
        :splitView="splitView"
        :selectedRange="selectedRange"
        @setSplitView="setSplitView"
        @setSelectedRange="setSelectedRange"
      />
    </SignalGroupChartStyled>
  </SignalGroupStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import Chart from './SignalGroup/Chart'
import SplitChartIcon from '@/assets/split_chart.svg'
import SingleChartIcon from '@/assets/single_chart.svg'

import { buttonReset, flexCenter } from '@styles/mixins'

const SignalGroupStyled = styled('div')`
  display: grid;
  grid-template-rows: 2rem 1fr;
  grid-gap: 0.25rem;
`
const SignalGroupHeaderStyled = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 2rem) 1fr;
  grid-template-rows: 2rem;
  grid-gap: 1px;
  button {
    width: 2rem;
    height: 2rem;
  }
`
const SignalGroupAssetNameStyled = styled('div')`
  ${flexCenter}
`
const SignalGroupChartStyled = styled('div')`
  position: relative;
`
const ButtonSolid = styled('button')`
  ${buttonReset}
  ${flexCenter}
  padding: 0;
  &.active {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primary};
  }
  &.disabled {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.muted};
    opacity: 0.3;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryLighter};
  }
  svg {
    path {
      fill: currentColor;
    }
  }
`

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  components: {
    SignalGroupStyled,
    SignalGroupHeaderStyled,
    SignalGroupAssetNameStyled,
    SignalGroupChartStyled,
    SplitChartIcon,
    SingleChartIcon,
    Chart,
    ButtonSolid,
  },
  data() {
    return {
      selectedRange: null,
      splitView: false,
    }
  },
  computed: {
    canMergeCharts() {
      return this.splitView
    },
    canSplitCharts() {
      return !this.splitView
    },
  },
  methods: {
    setSplitView(b) {
      this.splitView = b
    },
    setSelectedRange(range) {
      this.selectedRange = range
    },
    singleChart() {
      const c = this.$refs.chart
      if (c && typeof c.singleChart === 'function') {
        c.singleChart()
      }
    },
    splitCharts() {
      const c = this.$refs.chart
      if (c && typeof c.splitCharts === 'function') {
        c.splitCharts()
      }
    },
  },
}
</script>
