import { defineStore } from 'pinia'
import { groupBy } from 'lodash'
import { distinctColors } from '@styles/themes'
import { Interval, DateTime } from 'luxon'

// import SIGNAL_DATA_QUERY from '#/graphql/signals/signalData.gql'

export const useStore = defineStore('signals', {
  state: () => {
    return {
      selectedSignals: [],
      distinctColors: distinctColors(10, true),
    }
  },
  getters: {
    signalsGroupedByAsset() {
      const groups = groupBy(this.selectedSignals, 'asset.id')
      return Object.keys(groups).map(key => {
        const group = groups[key]
        return {
          id: key,
          asset: group[0].asset,
          signals: groups[key],
        }
      })
    },
  },
  actions: {
    selectSignal(signal, asset, interval = null) {
      const color = this.distinctColors.pop()
      const aggregator = 'FIRST'
      const isHidden = false
      const group = this.signalsGroupedByAsset.find(f => f.id === asset.id)
      let selectedInterval
      if (group) {
        selectedInterval = group.signals[0].selectedInterval
      } else {
        if (interval !== null) {
          selectedInterval = interval
        } else {
          const end = DateTime.fromISO(signal.validTill).endOf('hour').plus(1)
          const start = end.minus({ hour: 1 }).startOf('hour')
          selectedInterval = Interval.fromDateTimes(start, end).toISO()
        }
      }
      this.selectedSignals.push({ ...signal, color, aggregator, selectedInterval, asset, isHidden })
    },
    unselectSignal(signalId, assetId) {
      const found = this.selectedSignals.find(f => f.signalId === signalId && f.asset.id === assetId)
      this.distinctColors.push(found.color)
      this.selectedSignals = this.selectedSignals.filter(f => !(f.signalId === signalId && f.asset.id === assetId))
    },
    setAggregationForSignal(signalId, assetId, aggregator) {
      const found = this.selectedSignals.find(f => f.signalId === signalId && f.asset.id === assetId)
      found.aggregator = aggregator
    },
    setColorForSignal(signalId, assetId, color) {
      const found = this.selectedSignals.find(f => f.signalId === signalId && f.asset.id === assetId)
      found.color = color
    },
    toggleSignalIsHidden(signalId, assetId) {
      const found = this.selectedSignals.find(f => f.signalId === signalId && f.asset.id === assetId)
      found.isHidden = !found.isHidden
    },
    setSelectedInterval(groupId, interval) {
      const group = this.signalsGroupedByAsset.find(f => f.id === groupId)
      if (group) {
        group.signals.forEach(signal => {
          signal.selectedInterval = interval.toISO()
        })
      }
    },
  },
})
