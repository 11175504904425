<template>
  <SignalsStyled :isSearchExpanded="isSearchExpanded">
    <SignalTimelines @update-sub-selection="updateSubSelection" />
    <Signals :subSelections="subSelections" />
    <SignalSearch
      :assets="assets"
      :assetsLoading="$apollo.queries.assets.loading"
      :mobileIsActive="mobileActiveArea === 'search'"
      @toggleMobileActiveArea="toggleMobileActiveArea"
      @searchExpanded="searchExpanded"
      class="search"
    />
    <SignalControls :mobileIsActive="mobileActiveArea === 'controls'" @toggleMobileActiveArea="toggleMobileActiveArea"> </SignalControls>
    <SignalFooter @toggleMobileActiveArea="toggleMobileActiveArea" />
  </SignalsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'

import SignalTimelines from './Main/SignalTimelines'
import SignalControls from './Main/SignalControls'
import Signals from './Main/Signals'
import SignalSearch from './Main/SignalSearch'
import SignalFooter from './Main/SignalFooter'

const SignalsStyled = styled('div')`
  position: relative;
  display: grid;
  margin: 0.25rem 0 0 0.25rem;
  width: calc(100% - 0.5rem);
  height: calc(100% - 0.5rem);
  max-height: calc(100vh - 4.5rem);
  grid-gap: 0.25rem;
  grid-template-columns: auto;
  grid-template-rows: minmax(10rem, 1fr) minmax(min-content, 3fr) 2rem;
  grid-template-areas:
    'timelines'
    'signals'
    'footer';
  @media (min-width: 768px) {
    margin: 1rem 0 0 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 1rem);
    grid-gap: 1rem;
    grid-template-columns: auto 16rem;
    grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 4fr) minmax(10rem, 1fr);
    grid-template-areas:
      'timelines '
      'signals'
      'controls';
    .search {
      position: fixed;
      justify-self: right;
      width: ${({ isSearchExpanded }) => (isSearchExpanded ? '35rem' : '15rem')};
      border: solid 1px ${props => chroma(props.theme.colors.white).alpha(0.2).css()};
    }
  }
  @media (min-width: 1060px) {
    .search {
      width: ${({ isSearchExpanded }) => (isSearchExpanded ? '50rem' : '15rem')};
    }
  }
`

export default {
  components: {
    SignalsStyled,
    SignalControls,
    Signals,
    SignalSearch,
    SignalTimelines,
    SignalFooter,
  },
  data() {
    return {
      signalsSelected: [],
      assets: [],
      mobileActiveArea: null,
      subSelections: {},
      groups: [],
      isSearchExpanded: false,
    }
  },
  mounted() {
    this.$root.$on('addSelectedSignalsRangeToTable', this.onAddSelectedSignalsRangeToTable)
  },
  methods: {
    onAddSelectedSignalsRangeToTable(group) {
      const indexGroup = this.groups.findIndex(item => item.asset.id === group.asset.id)
      if (indexGroup < 0) {
        this.groups.push(group)
      } else {
        this.groups[indexGroup] = group
      }
      this.$root.$emit('activateOverlay', 'SignalTableOverlay', {
        groups: this.groups,
      })
    },
    toggleMobileActiveArea(area) {
      if (this.mobileActiveArea === area) {
        this.mobileActiveArea = null
      } else {
        this.mobileActiveArea = area
      }
    },
    updateSubSelection({ start, end, group }) {
      this.$set(this.subSelections, group.asset.id, {
        start,
        end,
      })
    },
    searchExpanded(event) {
      this.isSearchExpanded = event
    },
  },
  apollo: {
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
    },
  },
}
</script>
