var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalTimelinesStyled', [_c('h2', [_vm._v(_vm._s(_vm.$t('signals.headers.timelines')))]), _c('transition-group', {
    attrs: {
      "name": "fade"
    }
  }, _vm._l(_vm.signalsGrouped, function (group) {
    return _c('AssetSignalsTimeline', {
      key: group.asset.id,
      attrs: {
        "group": group
      },
      on: {
        "update-sub-selection": function updateSubSelection($event) {
          return _vm.$emit('update-sub-selection', $event);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }