<template>
  <ComponentNameStyled>
    <Sketch v-model="colorVal" :presetColors="presetColors" @input="updateColor" />
  </ComponentNameStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { Sketch } from 'vue-color'
import debounce from 'lodash/debounce'

import { distinctColors } from '@styles/themes'

const ComponentNameStyled = styled('div')``

export default {
  inject: ['theme'],
  props: {
    color: String,
  },
  components: {
    ComponentNameStyled,
    Sketch,
  },
  data() {
    return {
      active: false,
      colorVal: '#ccc',
      colors: [],
    }
  },
  // mounted() {
  //   this.colors = distinctColors()
  // },
  computed: {
    presetColors() {
      return distinctColors()
    },
  },
  watch: {
    color: {
      handler(data) {
        this.colorVal = data
      },
      immediate: true,
    },
  },
  methods: {
    updateColor: debounce(function (color) {
      this.$emit('update:color', color)
    }, 100),
  },
}
</script>
