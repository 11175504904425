<template>
  <DataFetcherStyled> </DataFetcherStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { Interval } from 'luxon'
import SIGNAL_DATA_QUERY from '#/graphql/signals/signalData.gql'

const DataFetcherStyled = styled('div')`
  display: none;
`

export default {
  props: {
    signal: {
      type: Object,
      required: true,
    },
  },
  components: {
    DataFetcherStyled,
  },
  computed: {
    selectedAggregator() {
      return this.availableAggregatorsMapped.find(f => f.id === this.signal.aggregator)
    },
    availableAggregatorsMapped() {
      return this.availableAggregators.map(({ name }) => ({
        id: name,
        label: this.$t('signals.aggregation.enums.' + name.toLowerCase()),
      }))
    },
    interval() {
      return Interval.fromISO(this.signal.selectedInterval)
    },
    validInterval() {
      return this.interval.isValid
    },
  },
  watch: {
    signalData: {
      handler(data) {
        this.$emit('set-data', data)
      },
    },
  },
  apollo: {
    signalData: {
      query: SIGNAL_DATA_QUERY,
      variables() {
        return {
          assetId: this.signal.asset.id,
          signalId: this.signal.signalId,
          signalShortName: this.signal.shortName,
          timeRange: {
            from: this.interval.start.toISO(),
            to: this.interval.end.toISO(),
            granularity: 'PT1S',
          },
          aggregator: this.signal.aggregator,
        }
      },
      skip() {
        return !this.signal || !this.signal.asset?.id || !this.validInterval || !this.signal.aggregator
      },
    },
  },
}
</script>
