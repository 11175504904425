<template>
  <ChartsStyled :id="group.asset.name">
    <DataFetcher
      v-for="signal in group.signals"
      :key="`${signal.asset.id}_${signal.signalId}`"
      :signal="signal"
      @set-data="setData(signal, $event)"
    />
  </ChartsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Chart from 'chart.js'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { DateTime, Interval } from 'luxon'
import { TimeframeMixin } from '@common/mixins'

import DataFetcher from '../../SignalTimelines/AssetSignalsTimeline/DataFetcher'

const isNumber = function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

const ChartsStyled = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
`

export default {
  mixins: [TimeframeMixin],
  inject: ['uiSettings', 'theme'],
  props: {
    group: {
      type: Object,
      required: true,
    },
    splitView: {
      type: Boolean,
      required: true,
    },
    selectedRange: {
      type: Object,
    },
  },
  components: {
    ChartsStyled,
    DataFetcher,
  },
  data() {
    return {
      options: {},
      charts: [],
      selectionRectangle: {
        width: 0,
        startX: 0,
      },
      signalTimezone: DateTime.local().zoneName,
      eventHandlersPresent: false,
      isDragging: false,
      startIndex: 0,
      signalData: {},
      pointsStartIndex: 0,
    }
  },
  computed: {
    locale() {
      return get(this.uiSettings, 'dates', 'DE_DE').toLowerCase().replace('_', '-')
    },
    signalsShown() {
      return this.group.signals.filter(f => !f.isHidden)
    },
    signalsWithData() {
      return this.signalsShown.filter(f => {
        return this.signalData[f.signalId]
      })
    },
    datasets() {
      const start = get(this.group, 'subSelectionStart', null)
      const end = get(this.group, 'subSelectionEnd', null)
      return this.signalsWithData
        .filter(s => !s.isHidden)
        .map(s => {
          const dataPoints = this.signalData[s.signalId].dataPoints
          const data = start !== null && end !== null ? dataPoints.slice(start, end) : dataPoints
          return {
            label: s.translatedName,
            yAxisID: s.signalId,
            aggregator: s.aggregator,
            unit: s.signalUnit?.name ? s.signalUnit.name : '-',
            data,
            borderColor: s.color,
            pointRadius: 1,
            borderWidth: 1,
          }
        })
    },
    labels() {
      const start = get(this.group, 'subSelectionStart', null)
      const end = get(this.group, 'subSelectionEnd', null)
      const firstSignal = this.signalsWithData[0]
      if (!firstSignal) {
        return []
      }
      const first = this.signalData[firstSignal.signalId]
      if (!first) {
        return []
      }

      const msStart = Interval.fromISO(firstSignal.selectedInterval).start.toUTC().toMillis()
      const labels = start !== null && end !== null ? first.msOffsets.slice(start, end) : first.msOffsets
      return labels.map(ms => msStart + ms)
    },
    yAxes() {
      const items = this.signalsWithData
      return items.map(s => {
        let unit = s.unit
        if (unit === 'degrees c') {
          unit = '°C'
        } else if (unit === 'percent') {
          unit = '%'
        }
        return {
          type: 'linear',
          id: s.signalId,
          ticks: {
            fontColor: s.color,
            callback: function (value) {
              const label = s.signalUnit?.name ? s.signalUnit.name : ''
              return value + ' ' + label
            },
          },
        }
      })
    },
  },
  created() {
    Chart.defaults.VerticalLine = Chart.defaults.line
    Chart.controllers.VerticalLine = Chart.controllers.line.extend({
      draw: function (ease) {
        Chart.controllers.line.prototype.draw.call(this, ease)

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          const activePoint = this.chart.tooltip._active[0]
          const ctx = this.chart.ctx
          const x = activePoint.tooltipPosition().x
          const topY = this.chart.legend.bottom
          const bottomY = this.chart.chartArea.bottom

          // draw line
          ctx.save()
          ctx.beginPath()
          ctx.moveTo(x, topY)
          ctx.lineTo(x, bottomY)
          ctx.lineWidth = 2
          ctx.strokeStyle = '#07C'
          ctx.stroke()
          ctx.restore()
        }
      },
    })

    const _this = this

    this.$root.$on('chartDrawVerticalLine', ({ tooltip, id }) => {
      _this.charts.forEach(chart => {
        const rect = chart.canvas.getBoundingClientRect()
        if (!rect || !tooltip) {
          return
        }
        const d = {
          clientX: rect.left + tooltip.x,
          clientY: rect.top + tooltip.y,
        }
        if (!isNumber(d.clientX) || !isNumber(d.clientY)) {
          return
        }
        if (chart?.canvas?.id === id) {
          // skip dispatch since it was the source chart
          return
        }
        const evt = new MouseEvent('mousemove', d)
        chart.canvas.dispatchEvent(evt)
      })
    })

    this.$root.$on('changeSignalTimezone', timezone => {
      this.signalTimezone = timezone
      _this.charts.forEach(chart => {
        chart.update()
      })
    })

    this.$root.$on('removeSelectedRange', ({ chartId }) => {
      this.onRemoveSelectedRange(chartId)
    })

    this.updateChart = debounce(
      () => {
        if (this.splitView) {
          this.splitCharts()
        } else {
          this.charts[0].options.scales.yAxes = this.yAxes
          this.charts[0].data.labels = this.labels
          this.charts[0].data.datasets = this.datasets
          this.charts[0].update()
        }
      },
      50,
      { trailing: true },
    )

    this.fireHoverEvents = debounce(
      (tooltip, id = 'unknown') => {
        this.$root.$emit('drawVerticalLineOverCharts', { tooltip, id })
      },
      0,
      { trailing: true },
    )
  },
  mounted() {
    this.$nextTick(() => {
      this.setupChart()
    })
  },
  destroyed() {
    this.removeEventListeners()
  },
  watch: {
    datasets: {
      handler() {
        if (this.updateChart) {
          this.updateChart('datasets')
        }
      },
      immediate: true,
    },
    labels: {
      handler() {
        if (this.updateChart) {
          this.updateChart('labels')
        }
      },
      immediate: true,
    },
    yAxes: {
      handler() {
        if (this.updateChart) {
          this.updateChart('yAxes')
        }
      },
      immediate: true,
    },
  },
  methods: {
    setData(signal, data) {
      this.$set(this.signalData, signal.signalId, data)
    },
    setupChart() {
      const firstSignalId = this.group.signals[0].id
      const chart = this.createChartCanvas(firstSignalId)

      this.addToView(chart)
      this.charts.push(chart)
      if (this.updateChart) {
        this.updateChart('initial')
      }
    },
    splitCharts() {
      this.charts = []
      this.deleteCharts()

      this.$emit('setSplitView', true)

      this.signalsWithData
        .filter(s => !s.isHidden)
        .forEach(signal => {
          const chart = this.createChartCanvas(signal.id)

          chart.options.scales.yAxes = this.populateYAxes(signal)
          chart.data.labels = this.populateLabels(signal)
          chart.data.datasets = this.populateDataSet(signal)
          chart.update()
          this.addToView(chart)
          this.charts.push(chart)
        })
    },
    singleChart() {
      const chart = this.charts[0]
      this.$emit('setSplitView', false)
      this.charts = []
      this.deleteCharts()

      this.charts.push(chart)
      this.updateChart()
      this.addToView(chart)
    },
    addEventListeners(chart) {
      chart.canvas.addEventListener('pointerdown', event => {
        this.startSelection(event, chart)
      })
      chart.canvas.addEventListener('pointermove', event => {
        this.adjustSelection(event, chart)
      })
      chart.canvas.addEventListener('pointerup', event => {
        this.endSelection(event, chart)
      })

      this.eventHandlersPresent = true
    },
    removeEventListeners() {
      this.charts.forEach(chart => {
        chart.canvas.removeEventListener('pointerdown', chart)
        chart.canvas.removeEventListener('pointermove', chart)
        chart.canvas.removeEventListener('pointerup', chart)
      })
      this.eventHandlersPresent = false
    },
    startSelection(evt, chart) {
      const points = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: false,
      })
      this.startIndex = get(points, '[0]._index', 0)
      const rect = chart.canvas.getBoundingClientRect()
      this.pointsStartIndex = get(points, '[0]._index', 0)
      this.selectionRectangle.startX = evt.clientX - rect.left
      this.isDragging = true

      chart.overlay.height = chart.height
      chart.overlay.width = chart.width
    },
    adjustSelection(evt, chart) {
      const rect = chart.canvas.getBoundingClientRect()

      if (this.isDragging) {
        this.selectionRectangle.width = evt.clientX - rect.left - this.selectionRectangle.startX
        this.renderOverlay(chart)
      }
    },
    endSelection(evt, chart) {
      const points = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: false,
      })
      this.pointsStartIndex = get(points, '[0]._index', 0)
      if (this.isDragging) {
        const start = Math.min(this.startIndex, this.pointsStartIndex)
        const end = Math.max(this.startIndex, this.pointsStartIndex)

        if (chart.data.datasets.length) {
          const rangeValue = chart.data.datasets[0].data.slice(start, end)
          const rangeTime = chart.data.labels.slice(start, end)
          const unit = chart.data.datasets[0].unit
          const sensorName = chart.data.datasets[0].label
          const date = this.selectedInterval.start.setLocale('de-de').toLocaleString()
          const timeZone = this.selectedInterval.start.zoneName
          const range = rangeValue.map((item, i) => {
            return {
              value: item,
              time: rangeTime[i],
            }
          })
          this.addPlottedRange(range, chart.canvas.id, unit, sensorName, date, timeZone)
        }
      }

      this.isDragging = false
    },
    renderOverlay(chart) {
      const ctx = chart.overlay.getContext('2d')
      if (!ctx) {
        return
      }
      ctx.clearRect(0, 0, chart.width, chart.height)

      ctx.globalAlpha = 0.5
      ctx.fillStyle = 'black'
      ctx.fillRect(this.selectionRectangle.startX, 0, this.selectionRectangle.width, chart.height)
    },
    createChartCanvas(signalId = '') {
      const canvas = document.createElement('canvas')
      const overlay = document.createElement('canvas')

      canvas.setAttribute('class', `chartCanvas ${signalId}`)
      canvas.setAttribute('id', signalId)
      overlay.setAttribute('class', `chartOverlay ${signalId}`)
      const ctx = canvas.getContext('2d')
      const chart = new Chart(ctx, {
        type: 'VerticalLine',
        data: {
          datasets: [],
          labels: [],
        },
        options: Object.assign(
          {
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                title: items => {
                  return DateTime.fromMillis(items[0]?.xLabel)
                    .setLocale(this.locale)
                    .toLocaleString(Object.assign(DateTime.TIME_WITH_SECONDS))
                },
                labelColor: (tooltip, { data }) => {
                  const set = data.datasets[tooltip.datasetIndex]
                  return {
                    borderColor: set.borderColor,
                    backgroundColor: set.borderColor,
                  }
                },
                label: (tooltip, data) => {
                  this.fireHoverEvents(tooltip, signalId)

                  const unit = data.datasets[tooltip.datasetIndex].unit
                  const sensorName = data.datasets[tooltip.datasetIndex].label

                  return `${sensorName}: ${tooltip.value} (${unit})`
                },
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: this.theme.colors.mediumGrey,
                    callback: value => {
                      return DateTime.fromMillis(value)
                        .setLocale(this.locale)
                        .toLocaleString(Object.assign(DateTime.TIME_WITH_SECONDS))
                        .toLowerCase()
                    },
                  },
                  maxBarThickness: 30,
                },
              ],
            },
          },
          this.options,
        ),
      })
      chart.overlay = overlay
      return chart
    },
    addToView(chart) {
      const container = document.getElementById(this.group.asset.name)
      const gridContainer = document.createElement('div')
      gridContainer.setAttribute('class', 'chartGrid')

      gridContainer.appendChild(chart.canvas)
      gridContainer.appendChild(chart.overlay)

      container.appendChild(gridContainer)
    },
    deleteCharts() {
      const container = document.getElementById(this.group.asset.name)
      container.querySelectorAll(`.chartGrid`).forEach(n => n.remove())
    },
    populateDataSet(signal) {
      const start = get(this.group, 'subSelectionStart', null)
      const end = get(this.group, 'subSelectionEnd', null)

      const dataPoints = this.signalData[signal.signalId].dataPoints

      const data = start !== null && end !== null ? dataPoints.slice(start, end) : dataPoints
      return [
        {
          label: signal.name,
          yAxisID: signal.name,
          unit: signal.signalUnit.name,
          data,
          borderColor: signal.color,
          pointRadius: 1,
          borderWidth: 1,
        },
      ]
    },
    populateLabels(signal) {
      const start = get(this.group, 'subSelectionStart', null)
      const end = get(this.group, 'subSelectionEnd', null)

      const msOffsets = this.signalData[signal.signalId].msOffsets
      const labels = start !== null && end !== null ? msOffsets.slice(start, end) : msOffsets
      const msStart = Interval.fromISO(signal.selectedInterval).start.toUTC().toMillis()
      return labels.map(ms => msStart + ms)
    },
    populateYAxes(signal) {
      return [
        {
          type: 'linear',
          id: signal.name,
          ticks: {
            min: 0,
            fontColor: signal.color,
            callback: function (value) {
              return value + ' ' + signal.signalUnit.name
            },
          },
        },
      ]
    },
    addPlottedRange(range, chartId, unit, sensorName, date, timeZone) {
      const selectedRange = {
        points: range.map(item => {
          return {
            value: item.value,
            time: item.time,
            sensorName,
            unit,
            date,
            timeZone,
          }
        }),
        asset: {
          id: this.group.asset.id,
          name: this.group.asset.name,
        },
        chartId,
      }
      this.$emit('setSelectedRange', selectedRange)
    },
    onRemoveSelectedRange(chartId) {
      // clear overlay
      const chart = this.charts.find(item => item.canvas.id === chartId)
      if (chart) {
        const ctx = chart.overlay.getContext('2d')
        ctx.clearRect(0, 0, chart.width, chart.height)
        this.$emit('setSelectedRange', null)
      }
    },
  },
}
</script>

<style lang="scss">
.chartGrid {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  grid-template-columns: 10rem;
}

.chartCanvas {
  grid-column: 1;
  grid-row: 1;
}

.chartOverlay {
  grid-column: 1;
  grid-row: 1;
  pointer-events: none;
  width: 100%;
  height: fit-content;
}
</style>
