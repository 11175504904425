var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ComponentNameStyled', [_c('Sketch', {
    attrs: {
      "presetColors": _vm.presetColors
    },
    on: {
      "input": _vm.updateColor
    },
    model: {
      value: _vm.colorVal,
      callback: function callback($$v) {
        _vm.colorVal = $$v;
      },
      expression: "colorVal"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }