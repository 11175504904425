<template>
  <SignalControlsStyled :class="{ active: mobileIsActive }">
    <h2>{{ $t('signals.headers.controls') }}</h2>
    <transition-group name="fade">
      <template v-for="group in signalsGrouped">
        <h3 :key="group.asset.id">{{ group.asset.name }}</h3>
        <SelectedSignal v-for="signal in group.signals" :key="`${signal.asset.id}_${signal.signalId}`" :signal="signal" />
      </template>
    </transition-group>
    <slot />
  </SignalControlsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { panel } from '@styles/mixins'

import SelectedSignal from './SignalControls/SelectedSignal'
import MobileArea from './mobileArea'

import { useStore } from '../../../stores/signals'

const SignalControlsStyled = styled(MobileArea)`
  ${panel}
  overflow-y: auto;

  @media (min-width: 768px) {
    grid-area: controls;
  }
`

export default {
  props: {
    mobileIsActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SignalControlsStyled,
    SelectedSignal,
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  computed: {
    signalsGrouped() {
      return this.signalStore.signalsGroupedByAsset
    },
  },
}
</script>
