<template>
  <SignalFooterStyled>
    <button @click="$emit('toggleMobileActiveArea', 'search')">
      <SearchIcon />
    </button>
    <button @click="$emit('toggleMobileActiveArea', 'controls')">
      <ListIcon />
    </button>
  </SignalFooterStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { panel, buttonReset, flexCenter, flexStartCenter } from '@styles/mixins'

import { SearchIcon, ListIcon } from 'vue-feather-icons'

const SignalFooterStyled = styled('div')`
  ${panel}
  ${flexStartCenter}
  grid-area: footer;

  button {
    ${buttonReset}
    ${flexCenter}
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export default {
  components: {
    SignalFooterStyled,
    SearchIcon,
    ListIcon,
  },
}
</script>
