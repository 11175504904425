<template>
  <SignalsStyled>
    <SignalGroup v-for="group in signalsGroupedWithSubSelection" :group="group" :key="group.asset.id" />
    <transition name="fade-in-left">
      <LoadingIndicatorStyled v-if="signalsLoading.length > 0">
        <LoadingDots />
        <span>loading {{ signalsLoading.length }}</span>
      </LoadingIndicatorStyled>
    </transition>
  </SignalsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'
import { panel, flexCenter } from '@styles/mixins'
import { LoadingDots } from '@common/components'
import SignalGroup from './Signals/SignalGroup'
import { useStore } from '../../../stores/signals'

const SignalsStyled = styled('div')`
  ${panel}
  position: relative;
  overflow-y: auto;
  grid-area: signals;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 100%;
  grid-auto-rows: minmax(400px, 1fr);
`

const LoadingIndicatorStyled = styled('div')`
  ${flexCenter}
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem;
  color: ${p => p.theme.colors.white};
  background: ${p => chroma(p.theme.colors.black).alpha(0.7).css()};
`

export default {
  props: {
    subSelections: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.$root.$on('drawVerticalLineOverCharts', params => {
      this.$root.$emit('chartDrawVerticalLine', params)
    })
  },
  components: {
    SignalsStyled,
    LoadingIndicatorStyled,
    LoadingDots,
    SignalGroup,
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  data() {
    return {
      signalsSelected: [],
    }
  },
  computed: {
    signalsGrouped() {
      return this.signalStore.signalsGroupedByAsset
    },
    signalsGroupedWithSubSelection() {
      return this.signalsGrouped.map(g => {
        const subSelection = get(this.subSelections, g.asset.id, null)
        if (subSelection) {
          return {
            ...g,
            subSelectionStart: subSelection.start,
            subSelectionEnd: subSelection.end,
          }
        } else {
          return {
            ...g,
            subSelectionStart: 0,
            subSelectionEnd: 3599,
          }
        }
      })
    },
    signalsLoading() {
      return []
    },
  },
}
</script>
