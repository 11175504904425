// import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'

export default styled('div')`
  @media (max-width: 767px) {
    position: absolute;
    background: ${p => p.theme.colors.solidBG};
    width: calc(100% - 1rem);
    height: calc(100% - 4rem);
    transition: transform 0.25s ease;
    transform: translateX(100%);
    &.active {
      transform: translateX(0);
    }
  }
`
