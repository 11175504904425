<template>
  <PreviewChartStyled :class="{ active: datasets.length > 0 }">
    <DataFetcher
      v-for="signal in group.signals"
      :key="`${signal.asset.id}_${signal.signalId}`"
      :signal="signal"
      @set-data="setData(signal, $event)"
    />
    <OverlayCanvasStyled :width="width" :height="height" ref="overlay" />
    <canvas :width="width" :height="height" ref="chartCanvas" />
  </PreviewChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Chart from 'chart.js'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { DateTime, Interval } from 'luxon'

import DataFetcher from './DataFetcher'

const PreviewChartStyled = styled('div')`
  flex-grow: 1;
  position: relative;
  height: 48px;
`

const OverlayCanvasStyled = styled('canvas')`
  pointer-events: none;
  position: absolute;
`

export default {
  inject: ['theme', 'uiSettings'],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  components: {
    PreviewChartStyled,
    OverlayCanvasStyled,
    DataFetcher,
  },
  data() {
    return {
      width: 1,
      height: 1,
      chart: null,
      overlay: null,
      eventHandlersPresent: false,
      isDragging: false,
      startIndex: 0,
      // pointsStartIndex: 0,
      selectionInitialized: false,
      selectionStartX: 0,
      selectionEndX: 0,
      options: {},
      signalData: {},
      // TODO: Refactor timezone change in future
      signalTimezone: DateTime.local().zoneName,
    }
  },
  computed: {
    signalsReady() {
      return this.group.signals.filter(s => s.selectedInterval && !s.isHidden)
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
    signalsShown() {
      return this.group.signals.filter(f => !f.isHidden)
    },
    signalsWithData() {
      return this.signalsShown.filter(f => {
        return this.signalData[f.signalId]
      })
    },
    datasets() {
      const datasets = this.signalsWithData.map(s => {
        return {
          label: s.translatedName,
          yAxisID: s.signalId,
          data: this.signalData[s.signalId].dataPoints,
          borderColor: s.color,
          hoverRadius: 0,
          pointRadius: 0.1,
          borderWidth: 1,
        }
      })
      return datasets
    },
    labels() {
      const firstSignal = this.signalsWithData[0]
      if (!firstSignal) {
        return []
      }
      const first = this.signalData[firstSignal.signalId]
      if (!first) {
        return []
      }

      const msStart = Interval.fromISO(firstSignal.selectedInterval).start.toUTC().toMillis()

      return (first?.msOffsets || []).map(ms => msStart + ms)
    },
    yAxes() {
      const items = this.signalsWithData
      if (items.length < 1) {
        return [
          {
            type: 'linear',
            display: false,
          },
        ]
      }
      return items.map(s => {
        return {
          type: 'linear',
          id: s.signalId,
          display: false,
        }
      })
    },
  },
  created() {
    this.updateChart = debounce(() => {
      const chart = this.chart
      if (!chart) {
        return
      }

      const datasets = this.datasets
      const labels = this.labels
      const yAxes = this.yAxes

      this.chart.options.scales.yAxes = yAxes
      this.chart.data.labels = labels
      this.chart.data.datasets = datasets
      this.chart.update()
    })

    // TODO: Refactor timezone change in future
    this.$root.$on('changeSignalTimezone', timezone => {
      this.signalTimezone = timezone

      if (this.updateChart) {
        this.updateChart('xAxes')
      }
    })
  },
  mounted() {
    this.width = this.$el.clientWidth
    this.height = this.$el.clientHeight

    this.$nextTick(() => {
      this.setupChart()
      this.setupOverlay()
    })
  },
  destroyed() {
    this.removeEventListeners()
  },
  watch: {
    datasets: {
      handler(data) {
        if (this.updateChart) {
          if (data.length > 0) {
            if (!this.selectionInitialized) {
              this.selectionInitialized = true
              this.selectionStartX = 0
              this.selectionEndX = this.width
              this.$emit('update-sub-selection', {
                start: 0,
                end: 3599,
                group: this.group,
              })
              this.renderOverlay()
              if (!this.eventHandlersPresent) {
                this.addEventListeners()
              }
              // }
            }
          } else {
            if (!this.eventHandlersPresent) {
              this.removeEventListeners()
            }
          }
          this.updateChart('datasets')
        }
      },
      immediate: true,
    },
    locale: {
      handler() {
        if (this.updateChart) {
          this.updateChart('xAxes')
        }
      },
      immediate: true,
    },
    labels: {
      handler() {
        if (this.updateChart) {
          this.updateChart('labels')
        }
      },
      immediate: true,
    },
    yAxes: {
      handler() {
        if (this.updateChart) {
          this.updateChart('yAxes')
        }
      },
      immediate: true,
    },
  },
  methods: {
    setData(signal, data) {
      this.$set(this.signalData, signal.signalId, data)
    },
    setupChart() {
      const elem = this.$refs.chartCanvas
      const ctx = elem.getContext('2d')
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [],
          labels: [],
        },
        options: Object.assign(
          {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  display: false,
                },
              ],
              xAxes: [
                {
                  display: true,
                  ticks: {
                    padding: -5,
                    callback: value => {
                      return DateTime.fromMillis(value)
                        .setLocale(this.locale)
                        .toLocaleString(Object.assign(DateTime.TIME_SIMPLE))
                        .toLowerCase()
                    },
                    fontColor: this.theme.colors.mediumGrey,
                  },
                  maxBarThickness: 30,
                },
              ],
            },
          },
          this.options,
        ),
      })
      if (this.updateChart) {
        this.updateChart('initial')
      }
    },
    setupOverlay() {
      this.overlay = this.$refs.overlay
    },
    addEventListeners() {
      this.eventHandlersPresent = true
      const elem = this.$refs.chartCanvas
      elem.addEventListener('pointerdown', this.startSelection)
      elem.addEventListener('pointermove', this.adjustSelection)
      document.addEventListener('pointerup', this.endSelection)
    },
    removeEventListeners() {
      const elem = this.$refs.chartCanvas
      if (elem) {
        elem.removeEventListener('pointerdown', this.startSelection)
        elem.removeEventListener('pointermove', this.adjustSelection)
        document.removeEventListener('pointerup', this.endSelection)
      }
      this.eventHandlersPresent = false
    },
    startSelection(evt) {
      // const elem = this.$refs.chartCanvas
      // const points = this.chart.getElementsAtEventForMode(evt, 'index', {
      //   intersect: false,
      // })
      // this.startIndex = get(points, '[0]._index', 0)
      // const rect = elem.getBoundingClientRect()
      // this.selectionStartX = evt.clientX - rect.left
      this.selectionStartX = evt.offsetX
      this.isDragging = true
      // this.pointsStartIndex = get(points, '[0]._index', 0)
    },
    adjustSelection(evt) {
      const elem = this.$refs.chartCanvas
      const rect = elem.getBoundingClientRect()
      const ctx = this.overlay.getContext('2d')
      if (this.isDragging) {
        this.selectionEndX = evt.clientX - rect.left
        ctx.clearRect(0, 0, rect.width, rect.height)
        this.renderOverlay()
      } else {
        ctx.clearRect(0, 0, rect.width, rect.height)
        const x = evt.clientX - rect.left
        this.renderOverlay()
        if (x > this.chart.chartArea.left) {
          ctx.fillStyle = 'white'
          ctx.fillRect(x, 0, 1, this.height)
        }
      }
    },
    endSelection(evt) {
      const start = (this.selectionStartX / Math.max(this.width, 1)) * 3599
      const end = (evt.offsetX / Math.max(this.width, 1)) * 3599
      if (this.isDragging) {
        this.$emit('update-sub-selection', {
          start: Math.min(start, end),
          end: Math.max(start, end),
          group: this.group,
        })
      }
      this.isDragging = false
      const elem = this.$refs.chartCanvas
      const rect = elem?.getBoundingClientRect()
      const ctx = this.overlay?.getContext('2d')
      if (!ctx || !rect) {
        return
      }
      ctx.clearRect(0, 0, rect.width, rect.height)
      this.renderOverlay()
    },
    renderOverlay() {
      const ctx = this.overlay?.getContext('2d')
      if (!ctx) {
        return
      }
      const start = Math.min(this.selectionStartX, this.selectionEndX)
      const end = Math.max(this.selectionStartX, this.selectionEndX)
      ctx.globalAlpha = 0.5
      ctx.fillStyle = 'black'
      ctx.fillRect(0, 0, start, this.height)
      ctx.fillRect(end, 0, this.width - end, this.height)
    },
  },
}
</script>
