<template>
  <AssetSignalsTimelineStyled>
    <div class="title">
      {{ group.asset.name }}
    </div>
    <ControlsStyled>
      <VPopover :placement="'bottom'" :open.sync="showSignalDatetimePicker" :popperOptions="popperOptions">
        <button class="date">
          <div>{{ intervalDate }}</div>
          <div class="times">{{ intervalTimes }}</div>
        </button>
        <template v-slot:popover>
          <SignalDatetimePicker
            :selectedInterval.sync="selectedInterval"
            :selectedTimezone.sync="selectedTimezone"
            :assetId="group.asset.id"
            @close="closeSignalDatetimePicker"
          />
        </template>
      </VPopover>
    </ControlsStyled>
    <PreviewWrapperStyled>
      <PreviewChart :group="group" @update-sub-selection="$emit('update-sub-selection', $event)" />
    </PreviewWrapperStyled>
  </AssetSignalsTimelineStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import chroma from 'chroma-js'
import isEqual from 'lodash/isEqual'

import { VPopover } from 'v-tooltip'

import { DateTime, Interval } from 'luxon'

import { flexCenter, flexColumns, flexStartCenter, buttonReset } from '@styles/mixins'

import PreviewChart from './AssetSignalsTimeline/PreviewChart'
import SignalDatetimePicker from './AssetSignalsTimeline/SignalDatetimePicker'

const AssetSignalsTimelineStyled = styled('div')`
  width: 100%;
  display: grid;
  overflow: hidden;
  background: ${({ theme }) => (theme.isDark ? theme.colors.darkGrey : 'none')};
  box-shadow: ${({ theme }) => theme.colors.widgetShadow};
  grid-template-rows: 1.5rem 2.5rem 48px;
  grid-template-columns: auto;
  grid-template-areas:
    'title'
    'controls'
    'preview';

  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    grid-template-rows: 48px;
    grid-template-columns: 6rem 9rem auto 7rem;
    grid-template-areas: 'title controls preview';
  }

  .title {
    ${flexCenter}
    grid-area: title;
    padding: 0.5rem;
    font-size: 0.8rem;
    line-height: 1rem;
  }
`

const ControlsStyled = styled('div')`
  width: 100%;
  grid-area: controls;
  .v-popover,
  .v-popover .trigger {
    width: 100%;
    height: 100%;
  }
  button.date {
    ${buttonReset}
    ${flexStartCenter}
    width: 100%;
    height: 100%;
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.primary};
    padding: 0.5rem 0.2rem;

    &:hover {
      color: ${p => p.theme.colors.white};
      background: ${p => p.theme.colors.primaryLighter};
    }

    @media (min-width: 768px) {
      ${flexColumns}
      .times {
        margin-left: 0;
      }
    }

    .times {
      min-width: 6rem;
    }
  }
`

const PreviewWrapperStyled = styled('div')`
  grid-area: preview;
  background: ${({ theme }) =>
    chroma(theme.isDark ? theme.colors.darkGrey : theme.colors.solidBG)
      .alpha(0.8)
      .css()};
`

export default {
  inject: ['uiSettings'],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetSignalsTimelineStyled,
    ControlsStyled,
    PreviewWrapperStyled,
    PreviewChart,
    VPopover,
    SignalDatetimePicker,
  },
  data() {
    return {
      isInitialRender: true,
      selectedTimezone: {
        id: 'browser',
        title: 'Browser',
        value: DateTime.local().zoneName,
      },
      showSignalDatetimePicker: false,
    }
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
    selectedInterval() {
      return Interval.fromISO(this.group.signals[0].selectedInterval)
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
    signals() {
      return this.group.signals
    },
    signalIDs() {
      return this.group.signals.map(signal => signal.id)
    },
    intervalTimes() {
      const locale = this.locale
      const interval = this.selectedInterval
      const start = interval.start.setLocale(locale).toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
      })
      const end = interval.end.setLocale(locale).toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
      })
      return `${start} - ${end}`
    },
    intervalDate() {
      const locale = this.locale
      const interval = this.selectedInterval
      return interval.start.setLocale(locale).toLocaleString(
        Object.assign(DateTime.DATE_SHORT, {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }),
      )
    },
    maxLastSeen() {
      let max = null
      this.signals.forEach(signal => {
        if (signal.lastSeen) {
          const dt = DateTime.fromISO(signal.lastSeen).startOf('hour')
          if (max === null || dt > max) {
            max = dt
          }
        }
      })
      return max
    },
  },
  watch: {
    maxLastSeen: {
      handler(max) {
        if (max) {
          if (this.isInitialRender) {
            this.isInitialRender = false

            if (this.signals[0].selectedInterval) {
              const [start, end] = this.signals[0].selectedInterval.split('/')

              this.selectedInterval = Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end))
            } else {
              this.selectedInterval = Interval.fromDateTimes(max, max.plus({ hours: 1 }))
            }
          } else if (this.selectedInterval === null) {
            this.selectedInterval = Interval.fromDateTimes(max, max.plus({ hours: 1 }))
          }
        }
      },
      immediate: true,
    },
    signalIDs: {
      handler(idsNow, idsBefore) {
        if (isEqual(idsNow, idsBefore)) {
          return
        }
      },
    },
  },
  methods: {
    closeSignalDatetimePicker() {
      this.showSignalDatetimePicker = false
    },
  },
}
</script>
